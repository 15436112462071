import XpImg from '@xpedeo/core/dist/components/XpImg'

export default XpImg.extend({
  render (h) {
    if (this.width && this.height && this.hotspots.length) {
      return h('div', {
        staticClass: 'xp-img',
        style: this.style
      }, [
        h('div',
          {
            staticClass: 'xp-img__background-cover'
          }
        ),
        h('svg', {
          attrs: {
            width: '100%',
            height: '100%',
            viewBox: `0, 0, ${this.width}, ${this.height}`,
            version: '1.1',
            'xmlns:xlink': 'http://www.w3.org/1999/xlink',
            role: 'img',
            'aria-labelledby': `binarylabel${this.imageID}`
          }
        }, [
          h('title', {
            attrs: {
              id: `binarylabel${this.imageID}`
            }
          },
          this.alt
          ),
          h('image', {
            attrs: {
              width: this.width,
              height: this.height,
              'xlink:href': this.url,
              'data-binary-src': this.src
            }
          }),
          ...this.hotspots.map(hotspot => {
            const hotspotShapAttrs = (() => {
              switch (hotspot.shape) {
                case 'rect':
                  return {
                    width: (hotspot.br.x - hotspot.tl.x) * this.width / 100,
                    height: (hotspot.br.y - hotspot.tl.y) * this.height / 100,
                    x: hotspot.tl.x * this.width / 100,
                    y: hotspot.tl.y * this.height / 100
                  }
                case 'circle':
                  return {
                    cx: hotspot.center.x * this.width / 100,
                    cy: hotspot.center.y * this.height / 100,
                    r: hotspot.radius * this.width / 100
                  }
                case 'polygon':
                case 'poly': {
                  const coords = []
                  hotspot.points.map((point) => {
                    coords.push(point.x * this.width / 100 + ',' + point.y * this.height / 100)
                  })
                  return {
                    points: coords.join(' ')
                  }
                }
              }
            })()
            return h(hotspot.shape === 'poly' ? 'polygon' : hotspot.shape, {
              staticClass: 'xp-img__svg-path',
              on: {
                touchend: (e) => { if (!this.isDragged) { if (e.cancelable === true) e.preventDefault(); this.hotspotClicked(hotspot) } },
                mouseup: (e) => { if (!this.isDragged) { if (e.cancelable === true) e.preventDefault(); this.hotspotClicked(hotspot) } }
              },
              attrs: Object.assign(
                {
                  role: 'link',
                  'aria-label': (hotspot.alt === '' || hotspot.alt === undefined) ? '' : hotspot.alt,
                  title: (hotspot.alt === '' || hotspot.alt === undefined) ? '' : hotspot.alt,
                  tabindex: 0,
                  fill: 'transparent'
                },
                hotspotShapAttrs
              )
            }, [])
          })
        ]),
        ...(!this.isLoading ? this.htmlIconHotspots.map(hotspot => h(
          'span',
          {
            class: `xp xp--large xp-img__hotspot-icon xp-img__hotspot-icon--${hotspot.icon}`,
            style: {
              top: ((((this.xpImageBoundingClientRect.height - this.svgImageBoundingClientRect.height) / 2) + (hotspot.centerYPercent * this.svgImageBoundingClientRect.height)) / this.xpImageBoundingClientRect.height) * 100 + '%',
              left: ((((this.xpImageBoundingClientRect.width - this.svgImageBoundingClientRect.width) / 2) + (hotspot.centerXPercent * this.svgImageBoundingClientRect.width)) / this.xpImageBoundingClientRect.width) * 100 + '%',
              borderRadius: (0.5 / window.devicePixelRatio * this.iconSize / 100) + 'rem',
              borderWidth: (4 / window.devicePixelRatio * this.iconSize / 100) + 'px',
              height: (6 / window.devicePixelRatio * this.iconSize / 100) + 'rem',
              margin: (-2 / window.devicePixelRatio * this.iconSize / 100) + 'rem' + ' 0 0 -' + (3 / window.devicePixelRatio * this.iconSize / 100) + 'rem',
              width: (6 / window.devicePixelRatio * this.iconSize / 100) + 'rem',
              fontSize: (hotspot.icon === 'display' ? (3 / window.devicePixelRatio * this.iconSize / 100) : (5 / window.devicePixelRatio * this.iconSize / 100)) + 'rem',
              paddingTop: hotspot.icon === 'display' ? (1 / window.devicePixelRatio * this.iconSize / 100) + 'rem' : '0'
            },
            attrs: {
              href: hotspot.href,
              title: (hotspot.alt === '' || hotspot.alt === undefined) ? '' : hotspot.alt
            },
            on: {
              touchend: (e) => { if (!this.isDragged) { if (e.cancelable === true) e.preventDefault(); this.hotspotClicked(hotspot) } },
              mouseup: (e) => { if (!this.isDragged) { if (e.cancelable === true) e.preventDefault(); this.hotspotClicked(hotspot) } }
            }
          }, hotspot.icon === 'display' && hotspot.icontext ? hotspot.icontext : ''
        )) : [])
      ])
    }
    return h('div', {
      class: 'xp-img',
      style: this.style
    }, [
      h('div',
        {
          staticClass: 'xp-img__background-cover'
        }
      ),
      h('img', {
        staticClass: 'xp-img__image',
        class: !this.isComplete ? 'preview' : '',
        attrs: { src: this.url, 'data-binary-src': this.src, alt: (this.alt === '' || this.alt === undefined) ? this.$xp.content.pageData.Title : this.alt },
        style: {
          filter: this.isLoading ? 'blur(10px)' : null
        },
        on: this.hotspots !== undefined && this.hotspots.length > 0
          ? {
            ...this.$listeners,
            mouseup: this.__hotspotClickHandler,
            touchend: this.__hotspotClickHandler
          }
          : this.$listeners // , 'touchend': this.__getHotspotLister(), 'mouseup': this.__getHotspotLister()}
      })
    ])
  }
})
